import router from '@/router'
import store from '@/store'
import axios from 'axios'
import { mock } from './mock'
import { Loading, Message, MessageBox } from 'element-ui'
import { sessionStorage } from '../storage'
import { getToken, getUserInfo, getDataSourceType } from '@/common/utils/auth'

// if (!store.state.token) {
//   store.commit('SET_TOKEN', sessionStorage.getItem('token'))
// }

const whiteList = ['wuye/getUserElevatorCount', 'wuye/getElevatorRunDataStatistics', 'wuye/getUnCivilizedDataStatistics', 'wuye/getUncivilizedTrend', 'wuye/getUnCivilizedDataStatisticsGroupByElevator', 'wuye/getElevatorEventStatisticsGroupByElevator']
const outOfControlUrlList = ['commonBigScreen/elevator/getElevatorsStatus']
// axios 配置

const http = axios.create({
    baseURL: 'https://iot.shmashine.com/api/',
    // baseURL: 'http://localhost:8001/api/',
    timeout: 30000,
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
    },
    transformRequest: [
        function(data, headers) {
            // headers.token = store.state.token 
            if (headers['Content-type'] === 'multipart/form-data') {
                return data
            } else {
                return JSON.stringify(data)
            }
        }
    ]
})

// var loadingInstance

// 请求拦截器
http.interceptors.request.use(
    config => {
        // loadingInstance = Loading.service({
        // fullscreen: true,
        // lock: true,
        // text: '正在请求数据...'
        // })

        // 开发环境下，如果请求是 post,put,patch,则打印数据体，方便调试
        if (process.env.NODE_ENV === 'development') {
            const { method } = config
            if (method === 'post' || method === 'put' || method === 'patch') {}
        }

        if (getToken())
            config.headers['Authorization'] = `Bearer ${getToken()}`
        try {
            const temp = JSON.parse(getUserInfo())
            config.headers['user_id'] = temp.vUserId
        } catch (error) {
            config.headers['user_id'] = config.data ? config.data.userName || '' : ''
        }

        let url = config['url']
        let url_white_list = whiteList
        url.replace("api\/commonBigScreen", "commonBigScreen")

        if (getDataSourceType() != undefined) { //1 是仪电 2 是麦信
            let dataSourceType = getDataSourceType()
            if (dataSourceType == 2)
                // url = url.replace('/maixin/wuye','/wuye')
                if (!url_white_list.includes(url)) {
                    url = url.replace('wuye', 'maixin\/wuye')
                    // url = url.replace('wuye','maixin\/wuye').replace("RuiJin\/searchFaultList", "maixin\/wuye\/searchFaultList")
                }

            config['url'] = url
        }


        return config
    },
    error => {
        // loadingInstance.close()
        Message.error({
            message: '请求失败'
        })
        return Promise.reject(error)
    }
)

// 响应拦截器
http.interceptors.response.use(
    res => {
        if (res.data.code != '0000') {
            if (res.data && res.data.type == 'application/octet-stream') {
                return Promise.resolve(res)
            } else {
                if (outOfControlUrlList.includes(res.config.url)) {
                    return Promise.resolve(res.data)
                } else {
                    let msg = res.data.message
                    Message.error({
                        message: msg ? msg : '请求失败'
                    })
                    return Promise.reject(res.data)
                }
            }
        } else {
            // let url = res.config.url
            // let mockSwitch = 1 
            // if (mockSwitch == 1) {
            //     let result = mock.start(url)
            //     if (result) return result
            // }
            return Promise.resolve(res.data)
        }
    },
    error => {
        if (error) {
            if (error.response) {
                if (error.response.status == 401) {
                    store.dispatch('user/resetToken').then(() => {
                        location.reload()
                    })
                    return
                }

            }
            Message.error({
                message: '请求失败'
            })
            return Promise.reject(error)

        }
    }
)

export default http